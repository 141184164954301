.req-status {
  display: flex;
  padding: 0.3125rem 1rem;
  justify-content: flex-end;
  align-items: center;
  gap: 0.625rem;

  border-radius: 62.4375rem;
  background: rgba(0, 28, 194, 0.12);
}

.req-text1 {
  color: var(--Primary, #014e97);
  text-align: right;
  font-size: 0.875rem;
  font-style: normal;
}

.f-size {
  font-size: small;
}
