.bg {
  background: linear-gradient(148deg, #004a98 0%, #350055 100%);
}

.c-center {
  display: flex;
  align-items: center;
  /* justify-content: ; */
}

.otp-input {
  background-color: #004a98 !important;
  width: 10vw;
}

/* old */
.image1 {
  width: 20vw;
}

.image2 {
  width: 4rem;
}

.image8 {
  width: 3rem;
}

.logIn {
  display: flex;
  width: 20vw;
  padding: 32px 32px 42px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 1rem;
  border-radius: 12px;
  background: var(--Accent, #fff);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.18);
}

.text1 {
  font-family: font;
  /* font-weight: bold; */
  font-size: x-large;
}

.text2 {
  color: #004a98;
  text-align: right;
  font-family: Cairo;
  font-size: 0.6rem;
  font-style: normal;
  font-weight: 400;
  /* line-height: 3rem;  */
}

.t201 {
  font-size: 0.65rem;
  margin-top: 5px;
}

.mr0 {
  margin-right: 0px !important;
}

/* .bold-600 {
    font-weight: 600 !important;
} */

.text3 {
  font-family: font;
  /* font-weight: 600; */
  font-size: 0.7rem;
}

.text4 {
  color: #080d2b;
  text-align: right;
  font-family: font;
  font-size: 0.6rem;
  font-style: normal;
  /* font-weight: 700; */
  /* margin-left: 20px; */
  /* line-height: 3rem;  */
}

.text5 {
  color: #080d2b;
  text-align: right;
  font-family: font;
  font-size: 0.6rem;
  font-style: normal;
  /* font-weight: 700; */
  margin-left: 10px;
}

.textInput1 {
  font-family: font !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  color: #014a97 !important;
  font-family: font !important;
  /* font-weight: 700 !important; */
}

.otp-input {
  margin: 100px !important;
}

.c-input {
  width: 100%;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8.5px 14px !important;
}

.css-1x5jdmq {
  padding: 8.5px 14px !important;
}

.MuiStack-root {
  padding-top: 0px !important;
  overflow: inherit !important;
}

.check-box1 {
  display: flex;
  width: max-content;
  min-width: 100%;
  height: 100%;
  /* padding: 0px 19px 1px 76.949px; */
  justify-content: flex-end;
  align-items: center;
  /* gap: 28.834px; */
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid var(--Gray-1, #333);
}

.check-box2 {
  display: flex;
  width: max-content;
  min-width: 100%;
  height: 100%;
  /* padding: 0px 19px 1px 76.949px; */
  justify-content: flex-end;
  align-items: center;
  /* gap: 28.834px; */
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid #014a97;
  background: #f1f4ff;
}

.css-4jnixx-MuiStack-root {
  overflow: unset !important;
  padding-top: 0 !important;
}

@media only screen and (max-width: 900px) {
  .login-root {
    display: flex !important;
    flex-direction: row !important;
    height: max-content !important;
    width: 70vw !important;
  }
  .logIn {
    width: 70vw !important;
    height: max-content !important;
  }

  .login-container {
    display: flex !important;
    flex-direction: row-reverse !important;
  }

  .image-container {
    height: auto !important;
    align-items: end !important;
    justify-content: flex-end !important;
    margin-right: 5% !important;
  }

  .image1 {
    width: 50vw;
    margin-bottom: 5%;
  }
}
