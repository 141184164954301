.box-1 {
	display: flex;
	gap: 5px;
	width: 100%;
	border-bottom: solid 1px #e7e7e7;
	height: 100%;
	align-items: center;
	justify-content: center;
	/* width: 10vw; */
}

.box-2 {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	width: 100%;
}

.box-3 {
	direction: rtl;
	width: 100%;
}

.box-4 {
	background-color: #fff;
	border-radius: 9px;
	border: 1px solid rgba(67, 122, 179, 0.45);
}

.box-5 {
	padding-bottom: 0%;
}

.text-1 {
	margin-right: 0%;
}

.m0 {
	margin: 0%;
}

.orange {
	color: #f2994a;
}

.admin-box1 {
	background-color: #fff;
	border-radius: 8px;
	width: 100%;
}
.admin-box2 {
	background-color: #fff;
}
