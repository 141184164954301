/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
}

/* mobile scale */
@media only screen and (max-width: 400px) {
  .scale {
    transform: scale(0.95);
  }
}

@media only screen and (max-width: 375px) {
  .scale {
    transform: scale(0.9);
  }
  .root-m {
    padding: 0px !important;
  }
}
@media only screen and (max-width: 500px) {
  .root-m {
    margin-inline: 0px !important;
  }

  .vw90 {
    width: 90vw;
  }

  .flex-c-r {
    flex-direction: column-reverse !important;
  }

  .w100 {
    width: 100% !important;
  }
}

.f-normal {
  font-family: font-normal !important;
}

@media only screen and (min-width: 401px) and (max-width: 500px) {
  .scale-r {
    transform: scale(0.96);
  }
}

@font-face {
  font-family: "font";
  src: local("font"), url(./assets/fonts/font-bold.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "font-med";
  src: local("font-med"), url(./assets/fonts/font-med.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "font-normal";
  src: local("font-normal"), url(./assets/fonts/font-med.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "font-light";
  src: local("font-normal"),
    url(./assets/fonts/font-regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Zarid";
  src: local("Zarid"),
    url(./assets/fonts/alfont_com_29LTZaridSlab-SemiBold.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "cairo";
  src: local("cairo"), url(./assets/fonts/Cairo-Bold.ttf) format("woff");
}
/* @font-face {
  font-family: 'cairo';
  src: local('cairo'), url(./assets/fonts/Cairo-Regular-3.ttf) format('woff');
} */
