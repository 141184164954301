.text6 {
	color: #011c50;
	text-align: right;
	font-family: font;
	font-size: 0.99rem;
	font-style: normal;
	/* font-weight: 600; */
	/* line-height: 3rem;  */
}
.text7 {
	color: rgba(1, 28, 80, 0.7);
	text-align: right;
	font-family: font;
	font-size: 0.99rem;
	font-style: normal;
	/* font-weight: 600; */
	/* line-height: 3rem;  */
}

.routes {
	margin-top: 20vh !important;
}
.Rectangle-18568 {
	width: 30px;
	height: 24px;
	margin: 0 0 0 15.9px;
	text-align: center;
	border-radius: 3px;
}

.SpanNumbers {
	width: 7px;
	height: 22px;
	font-family: Montserrat-Arabic;
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.22;
	letter-spacing: normal;
	text-align: left;
	color: #fff;
}

.menu-button {
	display: none !important;
}

.header-style {
	width: 135px;
	height: 43px;
	margin: 36px 52px 38px 1039px;
	font-family: Montserrat-Arabic-Regular;
	font-size: 35px;
	/* font-weight: 600; */
	font-stretch: normal;
	font-style: normal;
	line-height: 1.23;
	letter-spacing: normal;
	text-align: left;
	color: #232f34;
	text-size-adjust: auto;
}

.Line-14 {
	width: auto;
	min-width: 79.6vw;
	height: 0;
	margin: 30px 0 0;
	border: solid 2px rgba(112, 112, 112, 0.17);
}

.Line-15 {
	width: auto;
	min-width: 79.6vw;
	height: 0;
	border: solid 2px rgba(112, 112, 112, 0.17);
}
.Rectangle-2093 {
	width: auto;
	height: auto;
	margin: 24px 24px 24px 36px;
	padding: 29px 23px 29px 19px;
	border-radius: 10px;
	border: solid 0.3px rgba(112, 112, 112, 0.17);
	background-color: #fff;
	zoom: 0.7;
}

.new-users-count {
	width: 83px;
	height: 53px;
	margin: 0 0 10px 163px;
	font-family: Montserrat-Arabic-Regular;
	font-size: 43px;
	/* font-weight: bold; */
	font-stretch: normal;
	font-style: normal;
	line-height: 1.23;
	letter-spacing: normal;
	text-align: left;
	color: #f9aa33;
}

.new-users-count-label {
	width: 112px;
	height: 21px;
	margin: 10px 0 0 134px;
	font-family: Montserrat-Arabic-Regular;
	font-size: 16px;
	/* font-weight: 500; */
	font-stretch: normal;
	font-style: normal;
	line-height: 1.19;
	letter-spacing: normal;
	text-align: left;
	color: #387e7f;
	display: flex;
}

.Rectangle-2094 {
	width: 40px;
	height: 40px;
	margin: 7px 7px 7px 7px;
	padding: 15px 15px 15px;
	border-radius: 10px;
	background-color: #387e7f;
	display: flex;
	align-items: center;
	justify-content: center;
}

.grid-width {
	max-width: fit-content !important;
}

.grid-width-date {
	min-width: fit-content !important;
	max-width: 200px;
	margin-top: 10px !important;
	margin-left: 30px !important;
}

.menu-icon {
	font-size: 4rem !important;
}

.sideBar {
	background: linear-gradient(180deg, #004a98 0%, #350055 100%);
	height: 100vh;
	border-left: #dcdcdc solid 1px;
	width: 19.8vw;
	position: fixed;
	right: 0;
}

.sideBar-shrink {
	width: 7vw;
}

.active {
	color: #014a97 !important;
	background-color: #fff !important;
	border-radius: 5px !important;
	margin: 10px 20px !important;
	font-family: font !important;
}
.active-nav {
	color: #014a97 !important;
	background-color: rgba(1, 74, 151, 0.12) !important;
	border-radius: 5px !important;
	margin: 10px 20px !important;
	font-family: font !important;
}

.active-icone {
	color: #014a97 !important;
	filter: invert(85%) sepia(46%) saturate(3508%) hue-rotate(200deg)
		brightness(100%) contrast(100%) !important;
}

.reverse-nav {
	color: #1f1f1f !important;
	background-color: transparent !important;
	font-family: font !important;

	border-radius: 5px !important;

	margin: 10px 20px !important;
}
.reverse {
	color: #fff !important;
	background-color: transparent !important;
	font-family: font !important;

	border-radius: 5px !important;

	margin: 10px 20px !important;
}

.reverse-icone {
	color: #fff !important;
	/* filter: opacity(0.5) drop-shadow(0 0 0 #866 ) brightness(0.9) invert(1) contrast(200%) saturate(0.9);  */
}

.direction {
	text-align: right;
}

.displayNon {
	display: none;
}

.css-1guqvll-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused
	.MuiOutlinedInput-notchedOutline {
	border: none !important;
}
.css-1guqvll-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root
	.MuiOutlinedInput-notchedOutline {
	border: none !important;
}

@media screen and (max-width: 1200px) {
}
@media only screen and (max-width: 900px) {
	.sidebar-mobile {
		display: none;
	}
	/* .routes {
        margin-top: 10vh !important;
    }
    
    .sideBar-main {
        display: none;
    }
    .sideBar{
        min-width: max-content ;

    }
    .Rectangle-2093 {
        zoom: 0.8;
    }
    .grid-p {
        justify-content: center;
        width: 100vw !important;
    }

    .grid-width {
        min-width: 80vw;
        margin-right: 10vw;
        justify-content: center;
    }
    .grid-width-date {
        margin-right: 7vw !important;
    }
    .grid-date-start {
        justify-content: flex-start !important;
    }
    .Line-14 {
        min-width: 100vw;
    }
    .Line-15 {
        min-width: 100vw;
    }

    .menu-button {
        display: block !important;
        width: fit-content;
        height: max-content !important;
        position: absolute !important;
        top: 20px;
        color: #232f34 !important;
    }

    .muirtl-10hburv-MuiTypography-root {
        font-size: large !important;
    }
    .stack {
        zoom: 0.8;
        top: 7vh;
    } */
}
